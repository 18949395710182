// Buttons dark styles of the app 

@include color-mode(dark) {
  .btn-icon {
    background: $body-bg-dark;
    border-color: $border-color-dark;

    svg {
      color: $white;
    }
  }

  .btn {

    &.btn-primary {
      background-color: $shark;
      border-color: $shark;

      &:hover,
      &.show {
        background-color: $trout;
        border-color: $trout;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
      }
    }

    &.btn-primary:not(:disabled):not(.disabled):active {
      background-color: $shuttlegray;
      border-color: $shuttlegray;
    }

    &.btn-primary:disabled, 
    &.btn-primary.disabled {
      background-color: $shuttlegray;
      border-color: $shuttlegray;
    }

    &.btn-primary:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-primary:focus-visible {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
    }


    &.btn-secondary {
      background-color: $dovegray;
      border-color: $dovegray;

      &:hover,
      &.show {
        background-color: $oslogray;
        border-color: $oslogray;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
      }
    }

    &.btn-secondary:not(:disabled):not(.disabled):active {
      background-color: $mineshaft;
      border-color: $mineshaft;
    }

    &.btn-primary:disabled, 
    &.btn-primary.disabled {
      background-color: $shuttlegray;
      border-color: $shuttlegray;
    }

    &.btn-secondary:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-secondary:focus-visible {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
    }



    &.btn-outline-primary,
    &.btn-outline-secondary {
      border-color: $shark;
      color: $white;

      &:hover,
      &.show {
        background-color: $shark;
        border-color: $shark;
      }

      &:active {
        background-color: $shark;
        border-color: $shark;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
      }
    }

    &.btn-outline-primary:not(:disabled):not(.disabled):active,
    &.btn-outline-secondary:not(:disabled):not(.disabled):active {
      border-color: $shark;
    }

    &.btn-primary:disabled, 
    &.btn-primary.disabled {
      border-color: $shuttlegray;
    }

    &.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-outline-primary:focus-visible,
    &.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-outline-secondary:focus-visible {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
      background-color: $shark;
    }



    &.btn-link {
      color: $white;
      border-bottom-color: $white;
      box-shadow: none;

      &:hover {
        border-bottom-color: transparent;
      }

      &:active {
        color: $white;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.btn-link:not(:disabled):not(.disabled):active {
      border-bottom-color: $oslogray;
    }

    &.btn-link:not(:disabled):not(.disabled):active:focus,
    &.btn.btn-link:focus-visible {
      box-shadow: none;
      border-bottom-color: $oslogray;
    }
  }

}