// Forms dark styles of the app 

@include color-mode(dark) {
  
  .file-delete-hold {
    background-color: $border-color-dark;
  }

  .custom-file-field {
    [type=file] {
      + label {
        color: $white;
      }
    }
  }

  .form-control,
  .form-select {

    &:disabled,
    &.disabled {
      background-color: $shuttlegray;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
      border-color: $dovegray;
    }

  }

  .form-check-input {
    &:checked {
      background-color: $shark;
      border-color: $shark;
    }
    &:focus {
      box-shadow:  0 0 0 0.2rem rgba(255, 255, 255, 0.3);
      border-color: $border-color-dark;
    }
  }

  .custom-file {

    &:hover {
      .custom-file-label {
        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.2);
        }
      }
    }

    .custom-file-label {
      color: $white;
      border-color: $white;
      background: $body-bg-dark;
    }

    .form-control {

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.3);
      }

      &:not(:disabled) {
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          ~.custom-file-label {
            background: $body-bg-dark;
          }
        }
      }
    }
  }

  .maus-filter {
    .card-header {
      &.open {
        svg {
          color: $white;
        }
      }
    }
  }

  body {
    .react-select__multi-value {
      background-color: $tuna;
      color: $white;
    }
    .react-select__multi-value__label {
      color: $white;
    }
    .react-select__multi-value__remove {
      &:hover {
        background-color: $tuna;
        color: $white;
      }
    }
    .react-select__menu-list {
      border:1px solid $border-color-dark;
      border-radius: $border-radius;
    }
    .react-select__indicator {
      color: $white;
    }
    .react-select__indicator-separator {
      background-color: $border-color-dark;
    }
    .react-select__control  {
      border-color: $border-color-dark;
      background-color: $body-bg-dark;
      &:hover {
        border-color: $border-color-dark;
      }
    }
    .react-select__menu {
      background-color: $body-bg-dark;
    }
    .react-select__option {
      &:hover,
      &:focus,
      &:active {
        background-color: $tuna;
      }
    }
  }

  .maus-filter {

    .card-header,
    .collapse {
      border-color: $border-color-dark;
      background-color: $body-bg-dark;

      .card-body {
        .date-range-container {
          background: $body-bg-dark;
          border-color: $border-color-dark;
        }

        .date-range-divider {

          span {
            background: $body-bg-dark;
            color: $white;
          }

          &:before {
            content: '';
            background: $border-color-dark;
          }
        }
      }
    }
  }

  .react-datepicker {
    background-color: $body-bg-dark;
    color: $white;

    .react-datepicker__month-container {
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            background-color: $body-bg-dark;
            border-color: $body-bg-dark;
            color: $white;
          }

          .react-datepicker__day {
            &.react-datepicker__day--in-range {
              background-color: $oslogray;
              border-color: $oslogray;
              color: $body-bg-dark;
            }
          }
        }
      }
    }
  }

  .react-datepicker {
    .react-datepicker__month-container {
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            &.react-datepicker__day--selected {
              background-color: $white !important;
              border-color: $white;
              color: $body-bg-dark;

              &:hover {
                background-color: $gray-200 !important;
              }
            }

          }
        }
      }
    }
  }

  .react-datepicker {
    .react-datepicker__month-container {
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            &:hover {
              color: $body-bg-dark;
            }
          }
        }
      }
    }
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: $white;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $white;
  }

  .pagination-dropdown {
    .card-header,
    .collapse {
      border-color: $border-color-dark;
      background-color: $body-bg-dark;
    }
    .card-header {
      &.open {
        svg {
          color: $white;
        }
      }
    }
  }

  .app-block {
    .dropdown {
      border-color: $border-color-dark;
      background: $body-bg-dark;
    }
    .dropdown-menu {
      .dropdown-item {
        @include media-breakpoint-down(md) { 
          border-color: $border-color-dark;
        }
      }
    }
  }

  .site-chips {
    background: $tuna;
  }

  .checkbox_Container {
    input[type='checkbox'] {
      background: $body-bg-dark;
      border: 1px solid $border-color-dark;
      &:before {
        background: $gray-200;
        transition: $transition-base;
      }
      &:checked:before {
        background: $white;
      }
      &:checked {
        background: $shark;
        border: 1px solid $shark;
      }
    }
  }

  .alert-secondary {
    background: $border-color-dark;
    border-color: $shark;
    color: $white;
    .alert-link {
      color: $white;
    }
  }
  .sidebar-toggle{
    &:hover {
      svg {
        stroke: $white;
      }
    } 
  }
  
}
