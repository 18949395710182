@charset "UTF-8";

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Regular.otf') format('truetype');
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Thin.otf') format('truetype');
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-UltraLight.otf') format('truetype');
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Light.otf') format('truetype');
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Medium.otf') format('truetype');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Semibold.otf') format('truetype');
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Bold.otf') format('truetype');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Extrabold.otf') format('truetype');
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../../fonts/Gilroy-Black.otf') format('truetype');
	font-style: normal;
	font-weight: 900;
}
