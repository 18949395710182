// Sidebar dark styles of the app 

@include color-mode(dark) {
  .side-nav { 
    background: $body-bg-dark;
    border-color: $border-color-dark;
  }
  .sidebar {
    border-right: 1px solid $border-color-dark;
  }
}
