// Table dark styles of the app 

@include color-mode(dark) {
  .table {
    tbody {
      tr {
        position: relative;
        &.disabled {
          background-color: $shuttlegray;
        }
      }
    }
  }
}
