// Navbars dark styles of the app 

@include color-mode(dark) {

  .dropdown-item {
    &:active,
    &:focus {
      background-color: $trout;
    }
    &:hover {
      background-color: $shark;
    }
  }
  
  .btn-icon {
    background: $body-bg-dark;
    border-color: $border-color-dark;
    &:hover {
      border-color: $white;
    }
  }

  .nav-tabs,
  .profile-image-frame {
    border-color: $border-color-dark;
  }

  .nav-tabs {
    .nav-link {
      color: $white;
      &:hover,
      &:focus {
        border-color: $border-color-dark;
      }
      &.active {
        background-color: $shark;
        border-color: $shark;
      }
    }
  }

  #edly-navbar {
    @include media-breakpoint-down(lg) {
      background-color: $body-bg-dark;
    }
  }

  .nav {
    > li {
      > a, .nav-btn {
        &.active,
        &:hover {
          background-color: $shark;
          span {
            color: $white;
          }
          svg {
            stroke: $gray-300;
          }
        }
        span {
          color: $gray-100;
        }
        svg {
          stroke: $gray-100;
        }
      }
      .nav-btn {
        &.focused {
          background-color: rgba($color: #ffffff, $alpha: 0.02);
          span {
            color: $white;
          }
          svg {
            stroke: $gray-300;
          }
        }
      }
      ul {
        li {
          a {
            color: $oslogray;
            &:after {
              background-color: $oslogray;
            }
            &:hover {
              color: $white;
              background-color: rgba($color: #ffffff, $alpha: 0.02);
            }
            &.active {
              color: $white;
              background-color: rgba($color: #ffffff, $alpha: 0.02);
              &:after {
                background-color: $white;
              }
            }
          }
        }
      }
    }
    .iconTriangle {
      fill: $oslogray;
    }
  }

  .navbar {
    border-color: $border-color-dark;
    .nav-link {
      .img-profile {
        border-color: $border-color-dark;
      }
    }
  }

  .dropdown-menu,
  hr {
    border-color: $border-color-dark;
  }
  
  .navbar-nav {
    .nav-link {
      &:hover {
        color: $white;
      }
    }
  }
}
