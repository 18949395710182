
// Custom

$orange-white:  #FEF7EC;
$aqua-spring:   #EBF9F6;
$selago:        #F5F8FE;
$zircon:        #F0F5FF;
$mineshaft:     #252525;
$dovegray:      #666666;
$tuna:          #373A44;
$shark:         #40434f;
$trout:         #515665;
$shuttlegray:   #2e3139;

$oslogray:      #83858A;
$provincialpink:#FEF4F4;
$blue-light:    #6eabf6;
$green:         #62d586;
$alabaster:     #F9F9F9;
$porcelain:     #E9ECEE;
$seapink:       #EA868F;
$wildsand:      #F4F4F4;

// scss-docs-start gray-color-variables

$white:    #fff;
$gray-100: #F8F9FA;
$gray-200: #D9D9D9;
$gray-300: #EAEAEA;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$primary:   #DD1F25;
$secondary: #0258ED;

$body-bg-dark:  #0E111C;
$link-color-dark:  $primary;

$font-family-sans-serif: 'Gilroy', Arial, sans-serif;

// Customize the light and dark text colors for use in our color contrast function.

$color-contrast-dark:      $mineshaft;
$color-contrast-light:     $white;
$headings-color:           $mineshaft;

// scss-docs-start box-shadow-variables

$box-shadow:     0px 4px 4px 0px rgba($black, 0.02);

// scss-docs-end caret-variables

$transition-base:             all .2s ease-in-out;

// scss-docs-start border-variables

$border-color:          $gray-300;
$border-color-dark:     $tuna;
$border-radius:          0.5rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables

$btn-link-color:        $secondary;
$btn-link-hover-color:        $secondary;

$nav-tabs-border-color:             $border-color;
$nav-tabs-link-hover-border-color:  $border-color;
$nav-tabs-link-active-color:        $white;
$nav-tabs-link-active-bg:           $primary;
$nav-tabs-link-active-border-color: $primary;
$nav-tabs-link-active-bg: $primary;

