// Sidebar styles of the app 

.sidebar {
  transition: $transition-base;
  @include position(fixed,0,null,null,0);
  z-index: 2;
  .side-bar-active & {
    @include size(16.25rem, auto);
    @include media-breakpoint-down(lg) {
      margin-left: -16.25rem;
    }
  }
  .side-bar-inactive & {
    @include size(4.75rem, auto);
    @include media-breakpoint-down(lg) {
      margin-left: 0;
      @include size(16.25rem, auto);
    }
  }
}

.side-bar-inactive {
  .sidebar-toggle {
    svg {
      transform: rotate(180deg);
    }
  }
  .sidebar {
    &:hover {
      @include size(16.25rem, auto);
        .side-nav {
          padding: 1.25rem 1.25rem;
        }
        .settings-list {
          .iconTriangle {
            display: block;
          }
        }
        .nav {
          li { 
            .btn {
              font-size: 0.875rem;
            }
            .card-body {
              ul {
                li {
                  @include size(100%, auto);
                  overflow: hidden;
                }
              }
            }
            svg {
              margin-right: 1rem;
            }
            a {
              font-size: 0.875rem;
              @include size(100%, auto);
            }
          }
        }
        .navbar-brand {
            display: inline-block;
            transition: $transition-base;
            img {
              max-width: 15rem;
              max-height: 3rem;
              object-fit: contain;
              transition: $transition-base;
            }
        }
    }
  }
  .navbar-brand {
    @include media-breakpoint-up(lg) {
      transition: $transition-base;
      img {
        max-width: 3.125rem;
        max-height: 2.125rem;
        object-fit: contain;
        transition: $transition-base;
      }
    }
  }
  .logo-holder {
    @include d-flex(center,space-between,null,null);
  }
}

.navbar-brand {
  display: inline-block;
  transition: $transition-base;
  img {
    max-width: 15rem;
    max-height: 3rem;
    object-fit: contain;
    transition: $transition-base;
  }
}

.side-nav {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1.25rem;
  transition: padding 0.3s ease;
  @include multicolor-gradient($zircon,0%,$selago,25%,$aqua-spring,50%,$orange-white,100%);
  border-right: 1px solid $border-color;
}

.logo-holder {
  @include d-flex(center,space-between,null,null);
  @include media-breakpoint-up(lg) {
    min-height: 3.438rem;
  }
}

.sidebar-toggle {
  &:hover {
    cursor: pointer;
    svg {
      stroke: $secondary;
    }
  }
  svg {
    transition: stroke 0.3s ease;
  }
}
